import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

/** Componentes */
import { useAuth } from '../components/authprovider.component';

/** FIREBASE */
import FireBaseApp from "../firebase/firebase";


const Login = () => {
    const [loading, setLoading] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const navigate = useNavigate();
    const { user, loginAction } = useAuth();
    const [correo, setCorreo] = useState('');
    const [pass, setPass] = useState('');

    useEffect(() => {
        if (user) navigate("/profile");
    }, []);

    const ingresar = () => {
        setLoading(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!correo) {
            alert("Debe ingresar un correo!!");
            return;
        }
        if (!pass) {
            alert("Debe ingresar una contraseña!!");
            return;
        }
        setLoading(true);
        console.log("Loading ....");
        loginAction(correo, pass)
            .then(resp => {
                console.log(resp);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setMensaje("Error al autentificar!");
                setLoading(false);
            });
        //navigate("/profile");
        /* await delay(500);
        console.log(`Username :${inputUsername}, Password :${inputPassword}`);
        if (inputUsername !== "admin" || inputPassword !== "admin") {
            setShow(true);
        }
        setLoading(false); */
    };

    const resetPass = () => {
        if (!correo) {
            alert("Debe Ingresar el correo!!");
            return;
        }
        FireBaseApp.sendPasswordReset(correo);
    }

    return (
        <div
            className="sign-in__wrapper"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/img/fondo.jpg'})`,
                /* backgroundSize: 'cover',
                backgroundColor: 'azure',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '722px',
                top: '0px',
                left: '0px',
                position: "absolute", */
            }}>
            {/* Overlay */}
            <div className="sign-in__backdrop"></div>
            <Form className="shadow p-4 bg-white rounded" onSubmit={handleSubmit}>
                <img
                    src="/img/Ecora_New.jpg"
                    alt="Ecora"
                    //width="300px"
                    //height="150px"
                    className="w-100 mx-auto d-block mb-2"
                />
                <Form.Group className="mb-2">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Correo"
                        onChange={(e) => setCorreo(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Contraseña"
                        onChange={(e) => setPass(e.target.value)}
                    />
                </Form.Group>

                <div className="form-group">
                    <Button
                        type="submit"
                        className="w-100"
                        variant="primary"
                        disabled={loading}
                    >
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Login</span>
                    </Button>
                </div>

                {mensaje && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {mensaje}
                        </div>
                    </div>
                )}
                <Form.Group className="mt-3">
                    <Button
                        type="button"
                        className="w-100"
                        variant="secondary"
                        onClick={resetPass}
                    >Reestablecer Contraseña</Button>
                </Form.Group>
            </Form>
        </div>
    )

}

export default Login;