import React, { useEffect } from "react";
import { useAuth } from "../components/authprovider.component";

/** Componentes */
import NavbarMenu from "../components/navbar.component";

const Profile = () => {
    const auth = useAuth();

    useEffect(()=>{
        //console.log(auth);
    },[]);

    return (
        <div>
            <NavbarMenu auth={auth} />
            <div className="container mt-3">
                <header className="jumbotron">
                    <h3>
                        <strong>Bienvenido a Ecora {auth.user.nombre}</strong>
                    </h3>
                </header>
                <div className="text-center">
                    <img src="/img/logo_grande.png" alt="Ecora" />
                </div>
            </div>
        </div>
    );
}

export default Profile;