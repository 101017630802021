import React from "react";
import { useAuth } from '../components/authprovider.component';
/** BOOTSTRAP */
import { Nav, Tab } from 'react-bootstrap';

/** COMPONENTES */
import NavbarMenu from '../components/navbar.component';

const BoardGerencia = () => {
    const auth = useAuth();

    return (
        <div>
            <NavbarMenu auth={auth} />
        <div className="container mt-3 fondo_general">
            <header className="jumbotron bg-light p-4">
                <h3>Sección de Reportes Gerenciales</h3>
            </header>
            <br />
            <Tab.Container>
                <table className="table">
                    <tr>
                        <td></td>
                        <td><Nav.Link className="text-center" href="/reporterrhh/1/ReportSection52db391b319f618b7a7c" eventKey="#link3" aria-describedby="reporte_tarea"><img src="/img/iconos/detalle.png" alt="Reporte de Tareas" width="100" height="100" /></Nav.Link><div id="reporte_tarea" className="form-text text-center">Reporte General</div></td>
                        <td></td>
                    </tr>
                </table>
            </Tab.Container>
        </div>
        </div>
    );
}

export default BoardGerencia;