import React, { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
/** Servicios */
import FireBaseApp from "../firebase/firebase";
import usuarioService from "../services/usuario.service";
/** Componentes */
import { useLocalStorage } from "./uselocalstorage.component";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);//const [user, setUser] = useState(JSON.parse(localStorage.getItem("usuario"))?.user || null);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("usuario"))?.token || "");
    const navigate = useNavigate();

    const loginAction = async (username, password) => {
        return await new Promise(async (resolve, reject) => {
            try {
                console.log("User Name", username);
                const response = await FireBaseApp.logInWithEmailAndPassword(username, password);
                console.log("Response", response);
                if (response) {
                    console.log("Res2", response);
                    const uid = response.user.uid;
                    const _user = await usuarioService.getByUid({uid: uid});
                    console.log("USER", _user);
                    setUser(_user.data);
                    localStorage.setItem("usuario", JSON.stringify(_user.data));
                    navigate("/profile");
                    /* setUser(response.data.user);
                    setToken(response.data.accessToken);
                    var data = { user: response.data.user, token: response.data.accessToken }
                    localStorage.setItem("site", JSON.stringify(data));
                    resolve(data);
                    navigate("/upload"); */
                    return;
                } else {
                    reject("Error");
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("usuario");
        FireBaseApp.logout();
        navigate("/");
    };

    const value = useMemo(
        ()=>({
            user,
            token,
            loginAction,
            logOut
        }),
        [user]
    );

    return (
        <AuthContext.Provider value={ value }>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};