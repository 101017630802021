import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../components/authprovider.component";

const PrivateRoute = () => {
    const {user} = useAuth();
    console.log("AUTH PRIVATE", user);
    if (!user) return <Navigate to="/" />;
    return <Outlet />;
};

export default PrivateRoute;