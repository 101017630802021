// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";

import { getTranslatedError } from '@nafuzi/firebase-auth-error-translator';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC2RSOXyvqHaxx_LMhAUaFEmsZUvpcXa4c",
    authDomain: "reporteauth.firebaseapp.com",
    projectId: "reporteauth",
    storageBucket: "reporteauth.appspot.com",
    messagingSenderId: "953977275838",
    appId: "1:953977275838:web:30e291cf0a4d373188e5ef"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (email, password) => {
    console.log("EMail", email, "Pass", password);
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err);
        alert(getTranslatedError('es',err.code));
        throw err;
        //throw firebaseErrors[err.code] || err.message;
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link enviado!");
    } catch (err) {
        console.error(err);
        alert(err);
    }
};

const logout = () => {
    localStorage.removeItem("usuario");
    signOut(auth);
};

const FireBaseApp = {
    app:app,
    auth: auth,
    logInWithEmailAndPassword,
    logout,
    sendPasswordReset
}

export default FireBaseApp;